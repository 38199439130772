
















import {defineComponent, PropType, ref} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AssistedGradingWritten from '@/common/components/AssistedGradingWritten.vue';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';

export default defineComponent({
  name: 'ConcordiaChem206Experiment1Part1Explanation',
  components: {StembleLatex, AssistedGradingWritten, ApexChart: VueApexCharts},
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const inputs = ref({
      explanation: null as string | null,
    });

    const explanationGradingMap = props.task.content?.assistedGradingMap?.explanation ?? [];

    return {
      explanationGradingMap,
      inputs,
    };
  },
});
